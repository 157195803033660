'use client';

import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import DropdownIcon from '@/assets/Icons/Homepage/right-fill-dropdown-icon.svg';
import ProfileImage from '@/assets/Images/Profile/Male_User.png';
import AppStoreImage from '@/assets/Logo/app-store.png';
import Logo from '@/assets/Logo/logo.png';
import PlayStoreImage from '@/assets/Logo/play-store.png';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { logout } from '@/store/slices/authSlice';
import { persistor } from '@/store/store';
import { Menu, X } from 'lucide-react';

import LoginCard from '../common/LoginCard';
import ProfileDropdown from '../common/ProfileDropDown';
import WhyMedway from './navbar/WhyMedway';

const Navbar = () => {
  const [dropdown, setDropdown] = useState(null);
  const { data } = useAppSelector((state: any) => state.userProfile || {});
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const [profileDropdown, setProfileDropdown] = useState<boolean>(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const router = useRouter();
  const [showPopUp, setShowPopUp] = useState(false);

  const toggleDropdown = (menu: any) => {
    setDropdown(dropdown === menu ? null : menu);
  };

  useEffect(() => {
    if (isAuthenticated) {
      setProfileDropdown(false);
    }
  }, [isAuthenticated]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdown && !document.getElementById('dropdown-container')?.contains(event.target as Node)) {
        setDropdown(null);
      }

      if (profileDropdown && !document.getElementById('profile-dropdown')?.contains(event.target as Node)) {
        setProfileDropdown(false);
      }

      if (isMobileMenuOpen && !document.getElementById('mobile-menu')?.contains(event.target as Node)) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [dropdown, profileDropdown, isMobileMenuOpen]);

  const menuItems = [
    ...(isMobile
      ? []
      : [
          {
            label: 'Why Medway',
            type: 'button',
            key: 'why-medway',
            onClick: () => toggleDropdown('why-medway'),
          },
        ]),
    { label: 'About Us', type: 'link', href: '/aboutUs' },
    { label: 'Courses', type: 'link', href: '/courses' },
    {
      label: 'Batch',
      type: 'button',
      key: 'batch',
      onClick: () => toggleDropdown('batch'),
      dropdown: [
        { label: 'Sambhav Batch', href: '/batches/sambhav-batch' },
        { label: 'Live Classes', href: '/batches/live-classes' },
        // { label: 'Sawaal Bemisaal', href: '/batches/sawaal-bemisaal' },
      ],
    },
    { label: 'Plans', type: 'link', href: '/plans' },
    { label: 'Faculty', type: 'link', href: '/our-faculty' },
    { label: 'Contact', type: 'link', href: '/contact-us' },
  ];

  const toggleProfileDropdown = () => {
    setProfileDropdown(!profileDropdown);
  };

  const logoutHandler = () => {
    if (isAuthenticated) {
      setShowPopUp(false);
      dispatch(logout());
      persistor.purge();
      router.push('/');
    }
  };

  const logoutPopUp = () => {
    if (!showPopUp) return null;
    return (
      <div
        data-testid="logout-modal"
        id="modal-overlay"
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      >
        <div className="relative w-96 max-w-md scale-95 rounded-lg bg-white p-8 shadow-lg transition-transform duration-300 hover:scale-100">
          <button
            data-testid="btn-close-popup"
            className="absolute right-2 top-2 text-black hover:text-gray-500"
            onClick={() => setShowPopUp(false)}
          >
            ✕
          </button>
          <div className="mb-6 flex justify-center">
            <Image src={Logo} alt="Medway Logo" width={100} height={76} />
          </div>
          <p className="mb-6 text-center text-2xl font-semibold text-gray-800">
            Are you sure you want to <br />
            log out?
          </p>
          <div className="flex justify-center gap-x-4">
            <button
              data-testid="logout-btn"
              className="w-28 rounded-full bg-primary-green py-2 font-medium text-white transition-colors hover:bg-green-600"
              onClick={logoutHandler}
            >
              Yes
            </button>
            <button
              data-testid="btn-cancel-popup"
              className="w-28 rounded-full bg-gray-300 py-2 font-medium text-gray-800 transition-colors hover:bg-gray-400"
              onClick={() => setShowPopUp(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <nav className="sticky top-0 z-50 w-full bg-custom-gradient p-4 lg:h-20 lg:px-6">
      <div className="flex items-center justify-between lg:mx-auto lg:max-w-screen-xl">
        {/* Mobile Layout (reversed order for desktop) */}
        <div className="flex items-center gap-x-2 lg:hidden">
          {/* Hamburger Menu Button - for mobile */}
          <button
            data-testid="mobile-menu-toggle"
            id="mobile-menu-button"
            className="text-gray-600"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label={isMobileMenuOpen ? 'Close mobile menu' : 'Open mobile menu'}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Logo - Now second */}
          <Link href="/">
            <Image src={Logo} alt="Medway Online" width={200} height={200} className="size-16" />
          </Link>
        </div>

        {/* Desktop-only logo */}
        <Link href="/" className="hidden lg:block">
          <Image src={Logo} alt="Medway Online" width={100} height={100} className="size-16" />
        </Link>

        {/* Desktop Menu */}
        <ul className="hidden space-x-6 text-gray-600 lg:flex">
          {menuItems.map((item: any) => {
            if (item.type === 'button') {
              return (
                <li key={item.label} className="relative">
                  <button className="flex items-center space-x-1" onClick={item.onClick}>
                    <span>{item.label}</span>
                    <Image
                      src={DropdownIcon}
                      alt="Explore"
                      className={`mt-0.5 w-3 ${dropdown === item.key ? 'rotate-180' : 'rotate-0'} duration-200`}
                    />
                  </button>
                  {item.dropdown && dropdown === item.key && (
                    <div className="absolute -left-10 top-full mt-2 w-40 bg-white shadow-lg">
                      <ul className="space-y-2 p-4">
                        {item.dropdown.map((subItem: any) => (
                          <li key={subItem.label}>
                            <Link
                              href={subItem.href}
                              className="block text-gray-600 hover:text-gray-900"
                              onClick={() => setDropdown(null)}
                            >
                              {subItem.label}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              );
            } else if (item.type === 'link') {
              return (
                <li key={item.label}>
                  <Link href={item.href}>{item.label}</Link>
                </li>
              );
            }
          })}
        </ul>

        {/* Store Links and Profile/Login Section */}
        <div className="flex items-center gap-x-2 lg:gap-x-2">
          <div className="flex items-center gap-x-2 lg:gap-x-2">
            <Link href="https://play.google.com/store/apps/details?id=com.affinity.medway&hl=en">
              <Image src={PlayStoreImage} alt="Google Play" width={86} height={40} />
            </Link>
            <Link href="https://apps.apple.com/in/app/medway/id6477326093">
              <Image src={AppStoreImage} alt="App Store" width={86} height={40} />
            </Link>

            {isAuthenticated ? (
              <div className="relative">
                <button
                  data-testid="toggle-profile-btn"
                  className="flex cursor-pointer items-center gap-x-1 rounded-md border border-green-700 bg-gradient-to-b from-green-500 to-primary-green px-2 py-1 text-xs text-white shadow-md shadow-green-600/50 transition-all hover:scale-105 lg:px-3"
                  onClick={toggleProfileDropdown}
                >
                  <Image
                    src={ProfileImage}
                    alt="User Profile"
                    width={14}
                    height={14}
                    className="size-6 rounded-full border border-white shadow-sm lg:size-5"
                  />
                  <span className="text-xs font-medium text-white lg:text-sm">{data?.name || 'Profile'}</span>
                </button>

                <div id="profile-dropdown">
                  {profileDropdown && <ProfileDropdown onClickevent={() => setShowPopUp(true)} />}
                </div>
              </div>
            ) : (
              <button
                data-testid="nav-login-btn"
                className="rounded-md bg-primary-green px-3 py-1.5 text-xs text-white hover:bg-teal-700"
                onClick={() => setIsVisible(true)}
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div id="mobile-menu">
        {isMobileMenuOpen && (
          <div className="absolute inset-x-0 top-full z-50 bg-white shadow-lg lg:hidden">
            <ul className="space-y-2 px-4 py-2">
              {menuItems.map((item: any) => {
                if (item.type === 'button') {
                  return (
                    <li key={item.label}>
                      <button className="flex w-full items-center justify-between py-2" onClick={item.onClick}>
                        <span>{item.label}</span>
                        <Image
                          src={DropdownIcon}
                          alt="Explore"
                          className={`w-3 ${dropdown === item.key ? 'rotate-180' : 'rotate-0'} duration-200`}
                        />
                      </button>
                      {item.dropdown && dropdown === item.key && (
                        <ul className="mt-2 space-y-2 pl-4">
                          {item.dropdown.map((subItem: any) => (
                            <li key={subItem.label}>
                              <Link
                                href={subItem.href}
                                className="block py-2 text-gray-600"
                                onClick={() => {
                                  setDropdown(null);
                                  setIsMobileMenuOpen(false);
                                }}
                              >
                                {subItem.label}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                } else if (item.type === 'link') {
                  return (
                    <li key={item.label}>
                      <Link href={item.href} className="block py-2" onClick={() => setIsMobileMenuOpen(false)}>
                        {item.label}
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        )}
      </div>
      {logoutPopUp()}
      <div id="dropdown-container">{dropdown === 'why-medway' && <WhyMedway setDropdown={setDropdown} />}</div>
      {isVisible && <LoginCard onClose={() => setIsVisible(false)} />}
    </nav>
  );
};

export default Navbar;
