import { createSlice } from '@reduxjs/toolkit';

import { CreateOrderApi, PaymentDetailApi } from '../thunks/Plans/PaymentModelThunks';

interface InitiateOrderData {
  user_id: number;
  order_id: number;
  razorpay_order_id: string;
  plan_ids: number[];
  coupon_used: string;
  notes: {
    coupon_code: string;
    total_discount: number;
    plans_name: string[];
  };
  amount: number;
  total_discount: number;
}

interface PaymentDataResponse {
  status?: string;
  message?: string;
  plans?: {
    thumbnail?: string;
    name?: string;
    faculty?: string;
    faculty_image?: string;
    plan_purchase_date?: string;
    validity?: string;
    price?: number;
  }[];
}

interface PaymentData {
  InitiateOrderdata: InitiateOrderData | null;
  error: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  paymentStatus: PaymentDataResponse | null;
  isPopUpVisible: boolean | null;
}

const initialState: PaymentData = {
  InitiateOrderdata: null,
  error: null,
  status: 'idle',
  paymentStatus: null,
  isPopUpVisible: null,
};

const paymentSlice = createSlice({
  name: 'payment Model',
  initialState,
  reducers: {
    paymentStatus: (state) => {
      state.isPopUpVisible = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PaymentDetailApi.pending, (state: any) => {
        state.status = 'loading';
      })
      .addCase(PaymentDetailApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.InitiateOrderdata = action.payload;
      })
      .addCase(PaymentDetailApi.rejected, (state, action) => {
        state.error = action.payload as string;
      });

    builder
      .addCase(CreateOrderApi.pending, (state: any) => {
        state.status = 'loading';
      })
      .addCase(CreateOrderApi.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.paymentStatus = action.payload;
        if (state.paymentStatus?.status === 'success') {
          state.isPopUpVisible = true;
        }
      })
      .addCase(CreateOrderApi.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});
export const { paymentStatus } = paymentSlice.actions;
export default paymentSlice.reducer;
