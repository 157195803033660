import { createSlice } from '@reduxjs/toolkit';

import { UserProfile } from '../thunks/userProfileThunks';

interface UserProfile {
  data: string | null;
  error: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: UserProfile = {
  data: null,
  error: null,
  status: 'idle',
};
const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(UserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(UserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(UserProfile.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});
export default userProfileSlice.reducer;
