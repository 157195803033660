import { facultyDetail } from '@/store/thunks/facultyThunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FacultyCardProps } from '@/types/const';

export interface FacultyState {
  faculties: FacultyCardProps[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: FacultyState = {
  faculties: [],
  status: 'idle',
  error: null,
};

const facultySlice = createSlice({
  name: 'faculty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(facultyDetail.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(facultyDetail.fulfilled, (state, action: PayloadAction<FacultyCardProps[]>) => {
        state.status = 'succeeded';
        state.faculties = action.payload;
      })
      .addCase(facultyDetail.rejected, (state, action) => {
        state.status = 'failed';
        // Check if action.payload is undefined, and provide a default error message
        state.error = typeof action.payload === 'string' ? action.payload : 'Failed to fetch faculty details';
      });
  },
});

export default facultySlice.reducer;
