'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import DropdownIcon from '@/assets/Icons/Homepage/right-fill-dropdown-icon.svg';
import RightArrowIcon from '@/assets/Icons/Homepage/right-green-arrow.svg';
import YoutubeIcon from '@/assets/Icons/Homepage/youtube-color.svg';
import { batchData } from '@/constant/BatchData';
import { subjects, testData } from '@/constant/CommonData';
import { youtubeLink } from '@/constant/CommonLink';

const WhyMedway = ({ setDropdown }: any) => {
  const [contentActiveId, setContentActiveId] = useState('test');
  const [selectedId, setSelectedId] = useState('test');
  const [hoveredId, setHoveredId] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const menuItems = [
    { id: 'test', name: 'Test' },
    { id: 'qbank', name: "Q' Bank" },
    // { id: 'medwaySpecial', name: 'Medway Special' },
    { id: 'videos', name: 'Videos & Live Class Notes' },
    { id: 'notes', name: 'Notes' },
  ];

  const handleLinkClick = () => {
    setDropdown(null);
    setIsMobileMenuOpen(false);
  };

  const handleMenuItemClick = (itemId: string) => {
    setSelectedId(itemId);
    setContentActiveId(itemId);
    setIsMobileMenuOpen(false);
  };

  const renderContent: any = (id: string) => {
    switch (id) {
      case 'test':
        return (
          <div className="px-2 font-inter">
            {testData.map((data) => {
              const groupKey = Object.values(data)
                .map((test: any) => test.testName)
                .join('-');

              return (
                <div key={groupKey} className="grid grid-cols-1 gap-6 px-2 md:grid-cols-2 lg:grid-cols-3">
                  {Object.entries(data).map(([sectionKey, section]: [string, any]) => (
                    <div key={sectionKey} className="mb-6">
                      <Link
                        href={`/tests/${section.title?.replace(/\s+/g, '-')?.toLowerCase()}`}
                        onClick={handleLinkClick}
                      >
                        <h4 className="text-primary font-semibold">{section.title}</h4>
                      </Link>
                      <ul className="mt-2 list-outside list-disc space-y-1 text-sm font-normal text-secondary-gray">
                        {section.pointers.map((point: string) => (
                          <li key={`${sectionKey}-${point}`} className="text-sm">
                            {point}
                          </li>
                        ))}
                      </ul>
                      <div className="mt-2 space-y-1 text-sm">
                        <p className="font-semibold text-gray-400">Subjects</p>
                        <div className="mt-2">
                          <ul className="grid grid-cols-1 gap-2 text-sm text-slate-gray sm:grid-cols-2">
                            {section.subjects.list.slice(0, 8).map((subject: any) => (
                              <li key={subject.id} className="list-disc break-inside-avoid">
                                {subject.name}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        );
      case 'qbank':
        return (
          <div className="grid grid-cols-1 gap-8 px-4 font-inter">
            {/* RACE Section */}
            <div>
              <h4 className="text-lg font-bold text-secondary-green">RACE</h4>
              <p className="mb-4 text-sm text-secondary-green">Race</p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {subjects.map((subject) => (
                  <p key={subject.id} className="text-xs text-gray-700">
                    {subject.name} <span className="text-gray-400">({subject.questionBanks} Q&apos;Banks)</span>
                  </p>
                ))}
              </div>
            </div>

            {/* PYQ Section */}
            <div>
              <h4 className="text-lg font-bold tracking-wide text-secondary-green">PYQ</h4>
              <p className="mb-4 text-sm text-secondary-green">Previous Year Question</p>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                {subjects.map((subject) => (
                  <p key={subject.id} className="text-xs text-gray-700">
                    {subject.name} <span className="text-gray-400">({subject.questionBanks} Q&apos;Banks)</span>
                  </p>
                ))}
              </div>
            </div>
          </div>
        );
      case 'videos':
        return (
          <div className="px-4 font-inter">
            <div className="text-primary flex gap-x-1 text-xs">
              <Image src={RightArrowIcon} alt="Explore " className="w-2" />
              <p className="text-slate-gray">Live Class Notes</p>
            </div>
            <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {batchData?.liveClassNotes?.images.map((video, index) => (
                <Link
                  key={video.url}
                  href={video.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group block"
                >
                  <div className="relative overflow-hidden rounded-lg shadow-md">
                    <Image
                      src={video.src}
                      alt={`Video ${index + 1}`}
                      className="size-full object-cover transition-transform group-hover:scale-105"
                    />
                  </div>
                </Link>
              ))}
            </div>
            <div className="mt-4 flex flex-wrap items-center gap-2 text-xs">
              <p>Explore more videos on: </p>
              <Link
                href={`${youtubeLink}/${batchData?.liveClassNotes?.batch_youtube_url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-x-1 rounded-full border border-primary-green px-2 py-1 hover:scale-105"
              >
                <Image src={YoutubeIcon} alt="@Medwayedu Channel" className="w-4" />
                <span className="">@Medwayedu/liveNotes</span>
              </Link>
            </div>
          </div>
        );
      case 'notes':
        return (
          <div className="px-4 font-inter">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              {subjects.map((subject) => (
                <p key={subject.id} className="text-xs text-gray-700">
                  {subject.name} <span className="text-gray-400">({subject.questionBanks} Q&apos;Banks)</span>
                </p>
              ))}
            </div>
          </div>
        );
      default:
        return <div>Content not available</div>;
    }
  };

  return (
    <div className="absolute top-20 mt-2 w-full bg-white p-4 shadow-lg md:p-6 lg:max-w-screen-xl">
      <div className="flex flex-col md:flex-row">
        <button
          className="mb-4 flex items-center justify-between rounded-lg border p-2 text-sm font-medium md:hidden"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <span>{menuItems.find((item) => item.id === selectedId)?.name ?? 'Menu'}</span>
          <Image
            src={DropdownIcon}
            alt="Toggle Menu"
            className={`w-3 transition-transform${isMobileMenuOpen ? 'rotate-90' : '-rotate-90'}`}
          />
        </button>

        <div
          className={`w-full border-b pb-4 md:w-1/5 md:border-b-0 md:border-r md:pb-0 md:pr-4 ${isMobileMenuOpen ? 'block' : 'hidden md:block'}`}
        >
          <ul className="space-y-4">
            {menuItems.map((item: any) => (
              <li key={item.id} className="w-full">
                <button
                  type="button"
                  className="focus:ring-primary w-full text-left focus:outline-none focus:ring-2"
                  onMouseEnter={() => setHoveredId(item.id)}
                  onMouseLeave={() => setHoveredId(null)}
                  onClick={() => handleMenuItemClick(item.id)}
                  onFocus={() => setHoveredId(item.id)}
                  onBlur={() => setHoveredId(null)}
                >
                  {item.name}
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Dropdown Content */}
        <div className={`w-full md:w-3/4 md:pl-4 ${isMobileMenuOpen ? 'hidden' : 'block'}`}>
          {renderContent(hoveredId ?? contentActiveId)}
        </div>
      </div>
    </div>
  );
};

export default WhyMedway;
