import { API_ENDPOINTS } from '@/services/apiEndpoints';
import { getMedway } from '@/services/medway_api_services';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { FacultyCardProps } from '@/types/const';

export const facultyDetail = createAsyncThunk<FacultyCardProps[], void>('faculty/detail', async (_, thunkAPI) => {
  try {
    const response = await getMedway(API_ENDPOINTS.FACULTY_DETAILS);
    if (!response) {
      return null;
    }
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message || 'facultyDetails not found');
  }
});
