import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';

import authReducer from './slices/authSlice';
import counterReducer from './slices/counterSlice';
import facultyReducer from './slices/facultySlice';
import paymentReducer from './slices/PaymentModelSlice';
import planReducer from './slices/PlansSlice';
import userProfileReducer from './slices/userProfileSlice';
import userReducer from './slices/userSlice';

// Redux-Persist Configuration
const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['plan'],
};

const rootReducer = combineReducers({
  counter: counterReducer,
  user: userReducer,
  auth: authReducer,
  userProfile: userProfileReducer,
  faculty: facultyReducer,
  plan: planReducer,
  paymentReducer: paymentReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk),
});

// Persistor
export const persistor = persistStore(store);

// Infer the types for the store
export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
