interface DropDownProp {
  onClickevent: () => void;
}
const ProfileDropdown = ({ onClickevent }: DropDownProp) => {
  return (
    <div className="w-25 absolute left-1/2 mt-2 flex -translate-x-1/2 rounded-lg bg-white shadow-lg">
      <ul className="px-1 py-2 text-gray-700">
        <button
          className="cursor-pointer px-4 py-2 hover:bg-gray-100"
          onClick={onClickevent}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              onClickevent();
            }
          }}
        >
          Logout
        </button>
      </ul>
    </div>
  );
};
export default ProfileDropdown;
